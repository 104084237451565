import { betMessages } from 'constants/toastMessages';
import { confirmedBetSelector } from 'core/widgets/Bet';
import {
  clearAllBetsAction,
  placeBetSuccessAction,
  setConfirmedBetAction,
} from 'core/widgets/Bet/actions';
import { BetType, DiceBetType, IBet, IDiceBetBody } from 'core/widgets/Bet/types';
import { getBetSum, getSummaryBet } from 'core/widgets/Bet/utils';
import { addToastAction } from 'core/widgets/Toast/actions';
import { setTotalBetAction } from 'core/widgets/User/actions';
import { put, select } from 'redux-saga/effects';
import { DICE_SLOTS, Notifications, SLOTS } from 'types';
import { ToastTypes } from 'types/toast';
import { OnPendingBetsMadeData } from '../types';
import { EMPTY_BET } from 'core/widgets/Bet/data';
import { GameType } from 'constants/common';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { confirmedDiceBetSelector } from 'core/widgets/DiceBet';
import { IDiceBet } from 'core/widgets/DiceBet/types';
import { getSummaryDiceBet } from 'core/widgets/DiceBet/utils/getSummaryBet';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { placeDiceBetSuccessAction, setConfirmedDiceBetAction } from 'core/widgets/DiceBet/actions';
import { EMPTY_DICE_BET } from 'core/widgets/DiceBet/data';
import { IRoundState, RoundStates } from 'core/widgets/RoundStates/types';
import { roundStateSelector } from 'core/widgets/RoundStates';

export function* onPendingBetsMadeSaga({
  type: notificationType,
  data: result,
}: OnPendingBetsMadeData) {
  const gameType: GameType = yield select(gameTypeSelector);
  if (notificationType === Notifications.PENDING_BETS_MADE) {
    if (result.accepted) {
      const roundState: IRoundState['roundState'] = yield select(roundStateSelector);
      if (roundState === RoundStates.RoundCanceled) {
        return;
      }

      if (gameType === GameType.BAC) {
        // @TODO HHH
        const resultBets = result as BetType;

        const confirmedPendingBet: IBet = {
          id: new Date().getTime(),
          body: {
            [SLOTS.player]: resultBets.player,
            [SLOTS.tie]: resultBets.tie,
            [SLOTS.banker]: resultBets.banker,
          },
        };

        const confirmedBets: BetType[] = yield select(confirmedBetSelector);

        yield put(placeBetSuccessAction(EMPTY_BET));

        yield put(setTotalBetAction(result.total));
        yield put(setConfirmedBetAction(confirmedPendingBet));
        yield put(addToastAction({ type: ToastTypes.SUCCESS, value: betMessages.ACCEPTED }));
      }

      if (gameType === GameType.HHH) {
        // @TODO HHH
        const resultBets = result as DiceBetType;
        const confirmedBets: DiceBetType[] = yield select(confirmedDiceBetSelector);
        const totalConfirmedBets: DiceBetType = getSummaryDiceBet(confirmedBets);

        const confirmedPendingBet: IDiceBetBody = {
          id: new Date().getTime(),
          body: {
            [DICE_SLOTS.red]: resultBets.red,
            [DICE_SLOTS.orange]: resultBets.orange,
            [DICE_SLOTS.yellow]: resultBets.yellow,
            [DICE_SLOTS.green]: resultBets.green,
            [DICE_SLOTS.blue]: resultBets.blue,
            [DICE_SLOTS.purple]: resultBets.purple,
            [DICE_SLOTS.triple]: resultBets.triple,
          },
        };
        const betSum = getDiceBetSum([confirmedPendingBet.body]);

        yield put(placeDiceBetSuccessAction(EMPTY_DICE_BET));

        yield put(setTotalBetAction(result.total));
        yield put(setConfirmedDiceBetAction(confirmedPendingBet));
        yield put(addToastAction({ type: ToastTypes.SUCCESS, value: betMessages.ACCEPTED }));
      }
    }

    if (!result.accepted) {
      yield put(clearAllBetsAction());
    }
  }
}
