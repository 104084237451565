import audioVoiceTime from 'assets/sounds/voices/english/time_is_running_out.wav';
import audioVoiceTimeFr from 'assets/sounds/voices/french/time_is_running_out.wav';
import audioVoiceTimeCh from 'assets/sounds/voices/chinese/time_is_running_out.wav';
import audioVoiceTimeSp from 'assets/sounds/voices/spanish/time_is_running_out.wav';
import audioVoiceTimeRu from 'assets/sounds/voices/russian/time_is_running_out.wav';
import audioVoiceTimeHi from 'assets/sounds/voices/hindi/time_is_running_out.wav';
import audioVoiceTimePt from 'assets/sounds/voices/portuguese/time_is_running_out.wav';
import audioVoiceTimeTl from 'assets/sounds/voices/tagalog/time_is_running_out.wav';
import audioVoiceTimeId from 'assets/sounds/voices/bahasa/time_is_running_out.wav';
import audioVoiceTimeTr from 'assets/sounds/voices/turkish/time_is_running_out.wav';
import audioVoiceTimeDe from 'assets/sounds/voices/german/time_is_running_out.wav';

import audioVoiceWithoutBets from 'assets/sounds/voices/english/if_you_haven_decided.wav';
import audioVoiceWithoutBetsFr from 'assets/sounds/voices/french/if_you_haven_decided.wav';
import audioVoiceWithoutBetsCh from 'assets/sounds/voices/chinese/if_you_haven_decided.wav';
import audioVoiceWithoutBetsSp from 'assets/sounds/voices/spanish/if_you_haven_decided.wav';
import audioVoiceWithoutBetsRu from 'assets/sounds/voices/russian/if_you_haven_decided.wav';
import audioVoiceWithoutBetsHi from 'assets/sounds/voices/hindi/if_you_haven_decided.wav';
import audioVoiceWithoutBetsPt from 'assets/sounds/voices/portuguese/if_you_haven_decided.wav';
import audioVoiceWithoutBetsTl from 'assets/sounds/voices/tagalog/if_you_haven_decided.wav';
import audioVoiceWithoutBetsId from 'assets/sounds/voices/bahasa/if_you_haven_decided.wav';
import audioVoiceWithoutBetsTr from 'assets/sounds/voices/turkish/if_you_haven_decided.wav';
import audioVoiceWithoutBetsDe from 'assets/sounds/voices/german/if_you_haven_decided.wav';

import { LANGUAGES } from 'i18n';

export const timeSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceTime,
  [LANGUAGES.zh]: audioVoiceTimeCh,
  [LANGUAGES.fr]: audioVoiceTimeFr,
  [LANGUAGES.es]: audioVoiceTimeSp,
  [LANGUAGES.ru]: audioVoiceTimeRu,
  [LANGUAGES.hi]: audioVoiceTimeHi,
  [LANGUAGES.pt]: audioVoiceTimePt,
  [LANGUAGES.tl]: audioVoiceTimeTl,
  [LANGUAGES.id]: audioVoiceTimeId,
  [LANGUAGES.tr]: audioVoiceTimeTr,
  [LANGUAGES.de]: audioVoiceTimeDe,
  [LANGUAGES.el]: audioVoiceTime,
  [LANGUAGES.it]: audioVoiceTime,
  [LANGUAGES.cs]: audioVoiceTime,
};

export const noBetsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceWithoutBets,
  [LANGUAGES.zh]: audioVoiceWithoutBetsCh,
  [LANGUAGES.fr]: audioVoiceWithoutBetsFr,
  [LANGUAGES.es]: audioVoiceWithoutBetsSp,
  [LANGUAGES.ru]: audioVoiceWithoutBetsRu,
  [LANGUAGES.hi]: audioVoiceWithoutBetsHi,
  [LANGUAGES.pt]: audioVoiceWithoutBetsPt,
  [LANGUAGES.tl]: audioVoiceWithoutBetsTl,
  [LANGUAGES.id]: audioVoiceWithoutBetsId,
  [LANGUAGES.tr]: audioVoiceWithoutBetsTr,
  [LANGUAGES.de]: audioVoiceWithoutBetsDe,
  [LANGUAGES.el]: audioVoiceWithoutBets,
  [LANGUAGES.it]: audioVoiceWithoutBets,
  [LANGUAGES.cs]: audioVoiceWithoutBets,
};
