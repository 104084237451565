import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SLOTS } from 'types';
import { useModal, useCloseGame } from 'hooks';
import { PopupConfirm } from 'components';
import { confirmedBetSelector } from 'core/widgets/Bet';
import { getSummaryBet } from 'core/widgets/Bet/utils';
import { CloseGameButtonResponsive } from './Responsive';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType } from 'constants/common';
import { confirmedDiceBetSelector } from 'core/widgets/DiceBet';
import { getSummaryDiceBet } from 'core/widgets/DiceBet/utils/getSummaryBet';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';

export const CloseGameButton = () => {
  const { t } = useTranslation();
  const { isShown, openModal, closeModal } = useModal();
  const confirmedBets = useSelector(confirmedBetSelector);
  const confirmedBetsDice = useSelector(confirmedDiceBetSelector); // @TODO HHH
  const gameType = useSelector(gameTypeSelector); // @TODO HHH
  const { mode, lobbyUrl, closeGameMessage } = useSelector(authInfoSelector);

  const closeGame = useCloseGame(mode, closeGameMessage, lobbyUrl);

  const onCloseGameClick = useCallback(() => {
    if (gameType === GameType.BAC) {
      // @TODO HHH
      const betsSummary = getSummaryBet(confirmedBets);
      if (betsSummary[SLOTS.banker] + betsSummary[SLOTS.player] + betsSummary[SLOTS.tie]) {
        openModal();
        return;
      }
    }
    if (gameType === GameType.HHH) {
      // @TODO HHH
      const betsDiceSummary = getSummaryDiceBet(confirmedBetsDice);
      const betsSum = getDiceBetSum([betsDiceSummary]);
      if (betsSum > 0) {
        openModal();
        return;
      }
    }

    closeGame();
  }, [closeGame, confirmedBets, confirmedBetsDice, gameType, openModal]);

  return (
    <>
      {isShown ? (
        <PopupConfirm onConfirm={closeGame} onClose={closeModal}>
          {t('notices.exitWithBets')}
        </PopupConfirm>
      ) : null}
      <CloseGameButtonResponsive onCloseGame={onCloseGameClick} />
    </>
  );
};
