import { createAction } from 'typesafe-actions';
import { ACTIONS, BetSlotType, BetType, IBet } from './types';
import { SLOTS } from 'types';

export const placeBetAction = createAction(ACTIONS.PLACE_BET)<BetSlotType>();

export const placeBetSuccessAction = createAction(ACTIONS.PLACE_BET_SUCCESS)<BetType>();

export const placeBetOnClearSuccessAction = createAction(
  ACTIONS.PLACE_BET_ON_CLEAR_SUCCESS,
)<BetType>();

export const placeBetPendingAction = createAction(ACTIONS.PLACE_BET_PENDING)<SLOTS>();

export const clearPlacedBetAction = createAction(ACTIONS.CLEAR_PLACED_BET)<SLOTS>();

export const clearPlacedBetSuccessAction = createAction(ACTIONS.CLEAR_PLACED_BET_SUCCESS)<SLOTS>();

export const clearAllBetsAction = createAction(ACTIONS.CLEAR_ALL_BETS)();

export const confirmBetAction = createAction(ACTIONS.CONFIRM_BET)();

export const confirmBetSuccessAction = createAction(ACTIONS.CONFIRM_BET_SUCCESS)<number>();

export const confirmBetErrorAction = createAction(ACTIONS.CONFIRM_BET_ERROR)<number>();

export const addConfirmedBetAction = createAction(ACTIONS.ADD_CONFIRMED_BET)<IBet>();

export const setConfirmedBetAction = createAction(ACTIONS.SET_CONFIRMED_BET)<IBet>();
