import { DICE_SLOTS } from 'types';
import { preciseAddSubtract } from 'utils/roundToFix';
import { BetTypeDice } from '../types';
import { EMPTY_DICE_BET } from '../data';

export const getSummaryDiceBet = (bets: BetTypeDice[]): BetTypeDice =>
  Object.values(bets).reduce(
    (summaryBet, bet) => ({
      [DICE_SLOTS.red]: preciseAddSubtract(summaryBet[DICE_SLOTS.red], bet[DICE_SLOTS.red], 'add'),
      [DICE_SLOTS.orange]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.orange],
        bet[DICE_SLOTS.orange],
        'add',
      ),
      [DICE_SLOTS.yellow]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.yellow],
        bet[DICE_SLOTS.yellow],
        'add',
      ),
      [DICE_SLOTS.green]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.green],
        bet[DICE_SLOTS.green],
        'add',
      ),
      [DICE_SLOTS.blue]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.blue],
        bet[DICE_SLOTS.blue],
        'add',
      ),
      [DICE_SLOTS.purple]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.purple],
        bet[DICE_SLOTS.purple],
        'add',
      ),
      [DICE_SLOTS.triple]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.triple],
        bet[DICE_SLOTS.triple],
        'add',
      ),
    }),
    {
      ...EMPTY_DICE_BET,
    },
  );

export const getColorBetsCount = (bets: BetTypeDice): number => {
  return Object.entries(bets).filter(([key, value]) => key !== DICE_SLOTS.triple && value !== 0)
    .length;
};
