import React from 'react';
import { useSelector } from 'react-redux';

import { Device } from 'types';
import { diceStatisticsSelector } from 'core/widgets/DiceStatistics';

import { HistoryWrap, PercentageWrap, StatisticsWrapper } from './styles';
import { IDeviceBaseDiceStatisticsProps } from './types';
import { WinPercentage } from './WinPercentage';
import { WinHistory } from './WinHistory';

export const BaseDiceStatistics = ({
  deviceType = Device.Desktop,
}: IDeviceBaseDiceStatisticsProps) => {
  const diceData = useSelector(diceStatisticsSelector);

  return (
    <StatisticsWrapper $deviceType={deviceType}>
      <PercentageWrap>
        <WinPercentage diceData={diceData} />
      </PercentageWrap>
      <HistoryWrap>
        <WinHistory diceData={diceData} />
      </HistoryWrap>
    </StatisticsWrapper>
  );
};
