import { ActionType, createReducer } from 'typesafe-actions';
import {
  clearAllDiceBetsAction,
  clearPlacedDiceBetSuccessAction,
  placeDiceBetSuccessAction,
  confirmDiceBetSuccessAction,
  confirmDiceBetErrorAction,
  setConfirmedDiceBetAction,
  addConfirmedDiceBetAction,
  placeDiceBetOnClearSuccessAction,
} from './actions';
import { EMPTY_DICE_BET } from './data';
import { IDiceBetState } from './types';

export const diceBetState: IDiceBetState = {
  bets: {
    placed: EMPTY_DICE_BET,
    confirmed: [],
  },
};

const actions = {
  placeDiceBetSuccessAction,
  placeDiceBetOnClearSuccessAction,
  clearPlacedDiceBetSuccessAction,
  clearAllDiceBetsAction,
  confirmDiceBetSuccessAction,
  confirmDiceBetErrorAction,
  addConfirmedDiceBetAction,
  setConfirmedDiceBetAction,
};

export const diceBetReducer = createReducer<IDiceBetState, ActionType<typeof actions>>(diceBetState)
  .handleAction(placeDiceBetSuccessAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: payload,
    },
  }))
  .handleAction(placeDiceBetOnClearSuccessAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: payload,
    },
  }))
  .handleAction(clearPlacedDiceBetSuccessAction, (state, { payload: slot }) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: { ...state.bets.placed, [slot]: 0 },
    },
  }))
  .handleAction(clearAllDiceBetsAction, (state) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: EMPTY_DICE_BET,
      confirmed: [],
    },
  }))
  .handleAction(confirmDiceBetSuccessAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [
        ...state.bets.confirmed,
        {
          id: payload,
          body: state.bets.placed,
        },
      ],
      placed: EMPTY_DICE_BET,
    },
  }))
  .handleAction(confirmDiceBetErrorAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [...state.bets.confirmed.filter((confirmedBet) => confirmedBet.id !== payload)],
      placed: EMPTY_DICE_BET,
    },
  }))
  .handleAction(addConfirmedDiceBetAction, (state, { payload: confirmedBet }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [...state.bets.confirmed, confirmedBet],
    },
  }))
  .handleAction(setConfirmedDiceBetAction, (state, { payload: confirmedBet }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [confirmedBet],
    },
  }));
