import { ActionType } from 'typesafe-actions';
import { put, select } from 'redux-saga/effects';

import { preciseAddSubtract } from 'utils/roundToFix';
import { BetTypeDice } from '../types';
import { placeDiceBetAction, placeDiceBetSuccessAction } from '../actions';
import { placedDiceBetSelector } from '../selectors';
import { IDiceLimits } from 'constants/common';
import { tableDiceBetLimitSelector } from 'core/widgets/TableSettings';
import { balanceSelector } from 'core/widgets/User';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { addToastAction } from 'core/widgets/Toast/actions';
import { betMessages } from 'constants/toastMessages';
import { ToastTypes } from 'types/toast';
import { DICE_SLOTS } from 'types';
import { getColorBetsCount } from '../utils/getSummaryBet';

const MAX_COLOR_BETS = 5;

export function* placeDiceBetSaga({ payload: newBet }: ActionType<typeof placeDiceBetAction>) {
  const balance: number | null = yield select(balanceSelector);
  const placedBetResult: BetTypeDice = yield select(placedDiceBetSelector);
  const tableLimits: IDiceLimits = yield select(tableDiceBetLimitSelector);

  if (!balance || !tableLimits) {
    return;
  }

  const placedBet = placedBetResult;
  const placedBetSumBeforeNewBet: number = getDiceBetSum([placedBetResult]);

  if (placedBetSumBeforeNewBet >= tableLimits.totalMaxBet) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    return;
  }

  const availableRestBalance: number = preciseAddSubtract(
    balance,
    placedBetSumBeforeNewBet,
    'subtract',
  );

  if (!availableRestBalance) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
    return;
  }

  if (newBet.slot === DICE_SLOTS.triple) {
    if (placedBet[newBet.slot] >= tableLimits.tripleMaxBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      return;
    }

    const remainTotalBets =
      tableLimits.totalMaxBet - placedBetSumBeforeNewBet < tableLimits.tripleMaxBet
        ? tableLimits.totalMaxBet - placedBetSumBeforeNewBet
        : tableLimits.tripleMaxBet;

    const remainTripleBet = preciseAddSubtract(
      tableLimits.tripleMaxBet,
      placedBet[newBet.slot],
      'subtract',
    );

    if (placedBet[newBet.slot] === 0) {
      if (balance < tableLimits.tripleMinBet || availableRestBalance < tableLimits.tripleMinBet) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
        return;
      }

      if (remainTotalBets < tableLimits.tripleMinBet) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
        return;
      }

      if (newBet.value < tableLimits.tripleMinBet) {
        yield put(addToastAction({ type: ToastTypes.DEFAULT, value: betMessages.MIN_BET_REACHED }));
        const resultBet: BetTypeDice = {
          ...placedBet,
          [newBet.slot]: preciseAddSubtract(
            tableLimits.tripleMinBet,
            placedBet[newBet.slot],
            'add',
          ),
        };

        yield put(placeDiceBetSuccessAction(resultBet));
        return;
      }
    }

    const newAvailableBet = Math.min(remainTotalBets, remainTripleBet, newBet.value);

    const newAvailableBetCheckingBalance = newAvailableBet > balance ? balance : newAvailableBet;
    const newBetCheckingBalance =
      newBet.value > availableRestBalance ? availableRestBalance : newBet.value;

    const availableBetTriple =
      newBetCheckingBalance > newAvailableBetCheckingBalance
        ? newAvailableBetCheckingBalance
        : newBetCheckingBalance;

    const newTripleBet = preciseAddSubtract(availableBetTriple, placedBet[newBet.slot], 'add');

    const resultBet: BetTypeDice = {
      ...placedBet,
      [newBet.slot]: newTripleBet,
    };

    if (
      newAvailableBet < balance &&
      newAvailableBet < availableRestBalance &&
      (newBet.value > remainTripleBet || newBet.value > remainTotalBets)
    ) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    }

    yield put(placeDiceBetSuccessAction(resultBet));
    return;
  }

  const colorBetsCount = getColorBetsCount(placedBet);

  if (colorBetsCount >= MAX_COLOR_BETS) {
    yield put(
      addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_COLOR_BETS_REACHED }),
    );
    return;
  }

  if (placedBet[newBet.slot] >= tableLimits.colorMaxBet) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    return;
  }

  const remainTotalBets =
    tableLimits.totalMaxBet - placedBetSumBeforeNewBet < tableLimits.colorMaxBet
      ? tableLimits.totalMaxBet - placedBetSumBeforeNewBet
      : tableLimits.colorMaxBet;

  const remainColorBet = preciseAddSubtract(
    tableLimits.colorMaxBet,
    placedBet[newBet.slot],
    'subtract',
  );

  if (placedBet[newBet.slot] === 0) {
    if (balance < tableLimits.colorMinBet || availableRestBalance < tableLimits.colorMinBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    if (remainTotalBets < tableLimits.colorMinBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      return;
    }

    if (newBet.value < tableLimits.colorMinBet) {
      yield put(addToastAction({ type: ToastTypes.DEFAULT, value: betMessages.MIN_BET_REACHED }));
      const resultBet: BetTypeDice = {
        ...placedBet,
        [newBet.slot]: preciseAddSubtract(tableLimits.colorMinBet, placedBet[newBet.slot], 'add'),
      };

      yield put(placeDiceBetSuccessAction(resultBet));
      return;
    }
  }

  const newAvailableColorBet = Math.min(remainTotalBets, remainColorBet, newBet.value);

  const newAvailableBetCheckingBalance =
    newAvailableColorBet > balance ? balance : newAvailableColorBet;
  const newBetCheckingBalance =
    newBet.value > availableRestBalance ? availableRestBalance : newBet.value;

  const availableBetColor =
    newBetCheckingBalance > newAvailableBetCheckingBalance
      ? newAvailableBetCheckingBalance
      : newBetCheckingBalance;

  const newColorBet = preciseAddSubtract(availableBetColor, placedBet[newBet.slot], 'add');

  const resultBet: BetTypeDice = {
    ...placedBet,
    [newBet.slot]: newColorBet,
  };

  if (
    newAvailableColorBet < balance &&
    newAvailableColorBet < availableRestBalance &&
    (newBet.value > remainColorBet || newBet.value > remainTotalBets)
  ) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
  }

  yield put(placeDiceBetSuccessAction(resultBet));
  return;
}
