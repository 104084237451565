import styled, { css } from 'styled-components';
import { Device } from 'types';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const StatisticsWrapper = styled.div<{ $deviceType: Device }>`
  margin: 0 8rem;
  height: fit-content;
  width: 100%;
  position: absolute;
  bottom: 130rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        z-index: 100;
        margin: 0;
        bottom: ${isInIframe() ? '18rem' : '4rem'};
        gap: ${isInIframe() ? '22rem' : '64rem'};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 128rem;
        background: linear-gradient(180deg, #343434 0%, #2b2a2b 100%);

        ${StatisticBoardWrap} {
          position: static;
          height: 98rem;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        z-index: 100;
        margin: 0;
        bottom: ${isInIframe() ? '-4rem' : '0'};
        display: flex;
        justify-content: space-between;

        ${StatisticBoardWrap} {
          position: static;
        }
      `;
    }
  }}
`;

export const StatisticBoardWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
`;

export const PercentageWrap = styled(StatisticBoardWrap)`
  left: 0;
`;

export const HistoryWrap = styled(StatisticBoardWrap)`
  right: 0;
`;
