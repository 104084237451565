import { ActionType } from 'typesafe-actions';
import { call, put, select } from 'redux-saga/effects';
import { isTheLast2SecondsInBettingTimeSelector } from 'core/widgets/BettingTime';
import { IRoundState, RoundStates } from 'core/widgets/RoundStates/types';
import { roundStateSelector } from 'core/widgets/RoundStates';
import {
  ICreatePendingBetResponseData,
  ICreatePendingDiceBetRequestData,
  RestService,
} from 'services/RestAPIService';
import { truncationNumber } from 'utils/transformToCurrency';
import { clearDoubleBetAction } from 'core/widgets/BetDouble/actions';
import { clearRepeatBetAction } from 'core/widgets/BetRepeat/actions';
import { setConfirmationSlotAction } from 'core/widgets/BetConfirmation/actions';
import { setTotalBetAction } from 'core/widgets/User/actions';
import {
  clearPlacedDiceBetAction,
  clearPlacedDiceBetSuccessAction,
  placeDiceBetOnClearSuccessAction,
} from '../actions';
import { summaryDiceBetSelector } from '../selectors';
import { BetTypeDice } from '../types';
import { DICE_SLOTS } from 'types';
import { placeDiceBetSuccessAction } from '../actions';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import * as Sentry from '@sentry/react';

export function* clearDiceBetSaga({ payload }: ActionType<typeof clearPlacedDiceBetAction>) {
  const isTheLast2SecondsInBettingTime: boolean = yield select(
    isTheLast2SecondsInBettingTimeSelector,
  );
  const roundState: IRoundState['roundState'] = yield select(roundStateSelector);

  if (!isTheLast2SecondsInBettingTime) {
    yield put(clearPlacedDiceBetSuccessAction(payload));
    const placedBet: BetTypeDice = yield select(summaryDiceBetSelector);
    const data: ICreatePendingDiceBetRequestData = {
      [DICE_SLOTS.red]: truncationNumber(placedBet.red),
      [DICE_SLOTS.orange]: truncationNumber(placedBet.orange),
      [DICE_SLOTS.yellow]: truncationNumber(placedBet.yellow),
      [DICE_SLOTS.green]: truncationNumber(placedBet.green),
      [DICE_SLOTS.blue]: truncationNumber(placedBet.blue),
      [DICE_SLOTS.purple]: truncationNumber(placedBet.purple),
      [DICE_SLOTS.triple]: truncationNumber(placedBet.triple),
      timeStamp: new Date().getTime(),
    };
    yield put(placeDiceBetSuccessAction(data));
    yield put(clearDoubleBetAction());
    yield put(clearRepeatBetAction());
    yield put(setConfirmationSlotAction(null));
    return;
  }

  if (isTheLast2SecondsInBettingTime) {
    if (roundState === RoundStates.BettingTimeStarted) {
      const placedBet: BetTypeDice = yield select(summaryDiceBetSelector);
      const newBet = { ...placedBet, [payload]: 0 };
      const placedBetData: ICreatePendingDiceBetRequestData = {
        [DICE_SLOTS.red]: truncationNumber(placedBet.red),
        [DICE_SLOTS.orange]: truncationNumber(placedBet.orange),
        [DICE_SLOTS.yellow]: truncationNumber(placedBet.yellow),
        [DICE_SLOTS.green]: truncationNumber(placedBet.green),
        [DICE_SLOTS.blue]: truncationNumber(placedBet.blue),
        [DICE_SLOTS.purple]: truncationNumber(placedBet.purple),
        [DICE_SLOTS.triple]: truncationNumber(placedBet.triple),
        [payload]: 0,
        timeStamp: new Date().getTime(),
      };

      try {
        const response: ICreatePendingBetResponseData = yield call(() =>
          RestService.createPendingDiceBet(placedBetData),
        );

        const totalBet = getDiceBetSum([newBet]);
        yield put(setTotalBetAction(totalBet));
        yield put(placeDiceBetOnClearSuccessAction(placedBetData));
      } catch (error) {
        Sentry.captureException({ error, placedBetData });
        console.log(error);
      }
    }
  }
}
